function Profile() {
  return (
    <div>
      <div style={{padding:"20px 0"}}> About ME</div>
        <img style={{width:"300px",height:"300px", borderRadius:"100%"}} src={"profile.jpg"} alt=""/>
        <div>Name : Warakorn Konsila</div>
        <div>Age : 23 </div>
        <div>Birthday : 29/04/2000</div>
        <div>Region : Thai</div>
        
        
        
    </div>
  );
}

export default Profile;
